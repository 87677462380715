import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import CountGraph from "app/views/Graphs/Graphs/CountGraph";
import _ from "lodash";
//seeting
import { config } from "config.js";
const DetailGraphSection = ({
  state,
  functionContent,
  variablesObj,
  chartsVariableObj,
}) => {
  const {
    analysisChart,
    analysisChartData,
    nmuaUcChartData,
    nmuaUcChart,
    uaUcChartData,
    uaUcChart,
    gpCount,
    tbtChart,
    openChart,
    closeChart,
    newDays,
    investigationChart,
    internalCount,
    externalCount,
    employeeCount,
    contractorCount,
  } = chartsVariableObj;
  const { iraBasicInfo, reportStatus } = state;
  return (
    <>
      <div className="clide-v2-card-box-1 p-3 mt-2">
        <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold text-center pt-3 w-space-nobreak">
          {iraBasicInfo.activity} | Analysis
        </p>
        <hr />
        {iraBasicInfo.analysis?.fa_number == 0 &&
        iraBasicInfo.analysis?.mtc_number == 0 &&
        iraBasicInfo.analysis?.tra_number == 0 &&
        iraBasicInfo.analysis?.lti_number == 0 &&
        iraBasicInfo.analysis?.first_aid_number == 0 &&
        iraBasicInfo.analysis?.occupational_illness_number == 0 ? (
          <div className="graph-not-available-text">Graph not available</div>
        ) : (
          <div className="col-12  mb-3">
            <div className="clide-v2-white-box">
              <div className="row align-items-center">
                <div className="col-12  my-2">
                  <div className="sm-graph-modal-legend-text my-2 text-center">
                    <b>IRA Analysis</b>
                  </div>
                  {config.isChartJsEnabled ? (
                    functionContent.CountDoughnut(analysisChartData)
                  ) : (
                    <div style={{ width: "9vw" }}>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={analysisChart}
                      />
                    </div>
                  )}
                </div>
                <div className="col-12  ">
                  <div className="outer-legend-block">
                    <Scrollbars
                      style={{
                        height: `6rem`,
                        margin: "0.5rem 0.2rem 0.5rem 0",
                      }}
                      renderView={(props) => (
                        <div
                          style={{
                            paddingRight: "15px",
                            ...props.style,
                            overflowX: "hidden",
                          }}
                        />
                      )}
                    >
                      <div className="clide-v2-graph-left-box  d-flex align-items-center  ">
                        <span
                          className="soi-kanban-modal-legend-box clide-v2-square-span "
                          style={{ backgroundColor: "#FF4242" }}
                        ></span>
                        <span className="sm-graph-modal-legend-text ">FA</span>
                        <span className=" ml-auto">
                          {iraBasicInfo.analysis?.fa_number}
                        </span>
                      </div>
                      <div className="soi-kanban-inner-legend-block  ">
                        <span
                          className="soi-kanban-modal-legend-box clide-v2-square-span"
                          style={{ backgroundColor: "#086992" }}
                        ></span>
                        <span className="sm-graph-modal-legend-text my-2">
                          MTC
                        </span>
                        <span className="legend-number float-end">
                          {iraBasicInfo.analysis?.mtc_number}
                        </span>
                      </div>
                      <div className="soi-kanban-inner-legend-block  ">
                        <span
                          className="soi-kanban-modal-legend-box clide-v2-square-span"
                          style={{ backgroundColor: "#FAAC15" }}
                        ></span>
                        <span className="sm-graph-modal-legend-text my-2">
                          TRA
                        </span>
                        <span className="legend-number float-end">
                          {iraBasicInfo.analysis?.tra_number}
                        </span>
                      </div>
                      {/* /* // Britannia v01
  //  Making this change to ...(Integrate this rta to tra and faid oi notable integration because of britian custoimization ) */}

                      <div className="soi-kanban-inner-legend-block  ">
                        <span
                          className="soi-kanban-modal-legend-box clide-v2-square-span"
                          style={{ backgroundColor: "#18DA07" }}
                        ></span>
                        <span className="sm-graph-modal-legend-text my-2">
                          LTI
                        </span>
                        <span className="legend-number float-end">
                          {iraBasicInfo.analysis?.lti_number}
                        </span>
                      </div>
                      <div className="soi-kanban-inner-legend-block  ">
                        <span
                          className="soi-kanban-modal-legend-box clide-v2-square-span"
                          style={{ backgroundColor: "#C511FF" }}
                        ></span>
                        <span className="sm-graph-modal-legend-text my-2">
                          OI
                        </span>
                        <span className="legend-number float-end">
                          {iraBasicInfo.analysis?.occupational_illness_number}
                        </span>
                      </div>

                      <div className="soi-kanban-inner-legend-block  ">
                        <span
                          className="soi-kanban-modal-legend-box clide-v2-square-span"
                          style={{ backgroundColor: "#1589EE" }}
                        ></span>
                        <span className="sm-graph-modal-legend-text my-2">
                          First Aid
                        </span>
                        <span className="legend-number float-end">
                          {iraBasicInfo.analysis?.first_aid_number}
                        </span>
                      </div>

                      <div className="soi-kanban-inner-legend-block  ">
                        <span
                          className="soi-kanban-modal-legend-box clide-v2-square-span"
                          style={{ backgroundColor: "rgb(161, 255, 206)" }}
                        ></span>
                        <span className="sm-graph-modal-legend-text my-2">
                          Notable
                        </span>
                        <span className="legend-number float-end">
                          {iraBasicInfo.analysis?.notable_number}
                        </span>
                      </div>
                    </Scrollbars>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {iraBasicInfo.nm_ua_number == 0 && iraBasicInfo.nm_uc_number == 0 ? (
          <div className="graph-not-available-text">Graph not available</div>
        ) : (
          <div className="col-12  mb-3">
            <div className="clide-v2-white-box">
              <div className="row align-items-center">
                <div className="col-12  my-2">
                <div className="sm-graph-modal-legend-text text-center my-2">
                      <b>NM Analysis</b>
                    </div>
                  {config.isChartJsEnabled ? (
                    functionContent.CountDoughnut(nmuaUcChartData)
                  ) : (
                    <div style={{ width: "9vw" }}>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={nmuaUcChart}
                      />
                    </div>
                  )}
                </div>
                <div className="col-12 ">
                  <div className="outer-legend-block">
                   
                    <Scrollbars
                      style={{
                        height: `6rem`,
                        margin: "0.5rem 0.2rem 0.5rem 0",
                      }}
                      renderView={(props) => (
                        <div
                          style={{
                            paddingRight: "15px",
                            ...props.style,
                            overflowX: "hidden",
                          }}
                        />
                      )}
                    >
                      <div className="clide-v2-graph-left-box my-2 d-flex align-items-center  ">
                        <span
                          className="soi-kanban-modal-legend-box clide-v2-square-span "
                          style={{
                            backgroundColor: iraBasicInfo.analysis?.nm_ua_color,
                          }}
                        ></span>
                        <span className="sm-graph-modal-legend-text ">
                          Unsafe Act
                        </span>
                        <span className=" ml-auto">
                          {iraBasicInfo.analysis?.nm_ua_number}
                        </span>
                      </div>
                      <div className="soi-kanban-inner-legend-block  ">
                        <span
                          className="soi-kanban-modal-legend-box clide-v2-square-span"
                          style={{
                            backgroundColor: iraBasicInfo.analysis?.nm_uc_color,
                          }}
                        ></span>
                        <span className="sm-graph-modal-legend-text my-2">
                          Unsafe Condition
                        </span>
                        <span className="legend-number float-end">
                          {iraBasicInfo.analysis?.nm_uc_number}
                        </span>
                      </div>
                    </Scrollbars>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {iraBasicInfo.analysis?.ua_number == 0 &&
        iraBasicInfo.analysis?.uc_number == 0 ? (
          <div className="graph-not-available-text">Graph not available</div>
        ) : (
          <div className="col-12  mb-3">
            <div className="clide-v2-white-box">
              <div className="row align-items-center">
                <div className="col-12  my-2">
                <div className="sm-graph-modal-legend-text text-center my-2">
                      <b> UA Vs UC Analysis</b>
                    </div>
                  {config.isChartJsEnabled ? (
                    functionContent.CountDoughnut(uaUcChartData)
                  ) : (
                    <div style={{ width: "9vw" }}>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={uaUcChart}
                      />
                    </div>
                  )}
                </div>
                <div className="col-12  ">
                  <div className="outer-legend-block">
                    
                    <Scrollbars
                      style={{
                        height: `6rem`,
                        margin: "0.5rem 0.2rem 0.5rem 0",
                      }}
                      renderView={(props) => (
                        <div
                          style={{
                            paddingRight: "15px",
                            ...props.style,
                            overflowX: "hidden",
                          }}
                        />
                      )}
                    >
                      <div className="clide-v2-graph-left-box my-2 d-flex align-items-center  ">
                        <span
                          className="soi-kanban-modal-legend-box clide-v2-square-span "
                          style={{
                            backgroundColor: "#C7AC83",
                          }}
                        ></span>
                        <span className="sm-graph-modal-legend-text ">
                          Unsafe Act
                        </span>
                        <span className=" ml-auto">
                          {iraBasicInfo.analysis?.nm_ua_number}
                        </span>
                      </div>
                      <div className="soi-kanban-inner-legend-block  ">
                        <span
                          className="soi-kanban-modal-legend-box clide-v2-square-span"
                          style={{
                            backgroundColor: "#554040",
                          }}
                        ></span>
                        <span className="sm-graph-modal-legend-text my-2">
                          Unsafe Condition
                        </span>
                        <span className="legend-number float-end">
                          {iraBasicInfo.analysis?.unsafe_condition}
                        </span>
                      </div>
                    </Scrollbars>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {!(reportStatus == "pending" || reportStatus == "progress") ? (
        <>
          <div className="clide-v2-card-box-1 p-3 mt-2">
            <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold text-center pt-3 w-space-nobreak">
              Completion Days (Numbers)
            </p>
            <hr />
            <div className="row">
              <div className="col-12 col-lg-6 col-md-12 my-2">
                {config.isChartJsEnabled ? (
                  <CountGraph
                    count={state.capacount.map(
                      (data) => data.report_completion_days_count
                    )}
                    radius={"77%"}
                    cutout={"75%"}
                    height={100}
                     fontWeight={500}
                        fontSize={"1.5rem"}
                    color={state.capacount.map((data) => data.color)}
                  />
                ) : (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={investigationChart}
                  />
                )}
                <div class="w-100 text-center">
                  <span class="font-weight-bold fs-5">Investigation</span>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-md-12 my-2">
                {config.isChartJsEnabled ? (
                  <CountGraph
                    count={state.completioncount.map(
                      (data) => data.capa_completion_days_count
                    )}
                    radius={"77%"}
                    cutout={"75%"}
                    height={100}
                     fontWeight={500}
                        fontSize={"1.5rem"}
                    color={state.completioncount.map((data) => data.color)}
                  />
                ) : (
                  <HighchartsReact highcharts={Highcharts} options={newDays} />
                )}
                <div class="w-100 text-center">
                  <span class="font-weight-bold fs-5">CAPA</span>
                </div>
              </div>
            </div>
          </div>
          <div className="clide-v2-card-box-1 p-3 mt-2">
            <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold text-center pt-3 w-space-nobreak">
              CAPA Recommendation Count
            </p>
            <hr />
            <div className="row">
              <div className="col-12 col-lg-6 col-md-12 my-2">
                {config.isChartJsEnabled ? (
                  <CountGraph
                    count={!_.isEmpty(state.opencount) ? state.opencount?.map(
                      (data) => data.recommendation_open_count ?? 0
                    ) : 0 }
                    
                    radius={"77%"}
                    cutout={"75%"}
                    height={100}
                     fontWeight={500}
                        fontSize={"1.5rem"}
                    color={state.opencount.map((data) => data.color)}
                  />
                ) : (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={openChart}
                  />
                )}
                <div class="w-100 text-center">
                  <span class="font-weight-bold fs-5">Open</span>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-md-12 my-2">
                {config.isChartJsEnabled ? (
                  <CountGraph
                    count={state.closecount.map(
                      (data) => data.recommendation_close_count
                    )}
                    radius={"77%"}
                    cutout={"75%"}
                    height={100}
                     fontWeight={500}
                        fontSize={"1.5rem"}
                    color={state.closecount.map((data) => data.color)}
                  />
                ) : (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={closeChart}
                  />
                )}
                <div class="w-100 text-center">
                  <span class="font-weight-bold fs-5">Closed</span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      <div className="clide-v2-card-box-1 p-3 mt-2">
        <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold text-center pt-3 w-space-nobreak">
          Good Act & TBT Count
        </p>
        <hr />
        <div className="row">
          <div className="col-12 col-lg-6 col-md-12 my-2">
            {config.isChartJsEnabled ? (
              <CountGraph
                count={state.gpcount.map((data) => data.gp_count)}
                radius={"77%"}
                cutout={"75%"}
                height={100}
                 fontWeight={500}
                        fontSize={"1.5rem"}
                color={state.gpcount.map((data) => data.color)}
              />
            ) : (
              <HighchartsReact highcharts={Highcharts} options={gpCount} />
            )}
            <div class="w-100 text-center">
              <span class="font-weight-bold fs-5">GA</span>
            </div>
          </div>
          <div className="col-12 col-lg-6 col-md-12 my-2">
            {config.isChartJsEnabled ? (
              <CountGraph
                count={state.tbtcount.map((data) => data.tbt_count)}
                radius={"77%"}
                cutout={"75%"}
                height={100}
                 fontWeight={500}
                        fontSize={"1.5rem"}
                color={state.tbtcount.map((data) => data.color)}
              />
            ) : (
              <HighchartsReact highcharts={Highcharts} options={tbtChart} />
            )}
            <div class="w-100 text-center">
              <span class="font-weight-bold fs-5">TBT</span>
            </div>
          </div>
        </div>
      </div>

      <div className="clide-v2-card-box-1 p-3 mt-2">
        <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold text-center pt-3 w-space-nobreak">
          Internal & External Training Count
        </p>
        <hr />
        <div className="row">
          <div className="col-12 col-lg-6 col-md-12 my-2">
            {config.isChartJsEnabled ? (
              <CountGraph
                count={state.trainingInternalCount.map(
                  (data) => data.training_internal_count
                )}
                radius={"77%"}
                cutout={"75%"}
                height={100}
                 fontWeight={500}
                        fontSize={"1.5rem"}
                color={state.trainingInternalCount.map((data) => data.color)}
              />
            ) : (
              <HighchartsReact
                highcharts={Highcharts}
                options={internalCount}
              />
            )}
            <div class="w-100 text-center">
              <span class="font-weight-bold fs-5">Internal</span>
            </div>
          </div>
          <div className="col-12 col-lg-6 col-md-12 my-2">
            {config.isChartJsEnabled ? (
              <CountGraph
                count={
                  state.trainingExternalCount.map(
                    (data) => data.training_external_count
                  ) ?? 0
                }
                radius={"77%"}
                cutout={"75%"}
                height={100}
                 fontWeight={500}
                        fontSize={"1.5rem"}
                color={state.trainingExternalCount.map((data) => data.color)}
              />
            ) : (
              <HighchartsReact
                highcharts={Highcharts}
                options={externalCount}
              />
            )}
            <div class="w-100 text-center">
              <span class="font-weight-bold fs-5">External</span>
            </div>
          </div>
        </div>
      </div>

      <div className="clide-v2-card-box-1 p-3 mt-2">
        <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold text-center pt-3 w-space-nobreak">
          Employee & Contractor Training Count
        </p>
        <hr />
        <div className="row">
          <div className="col-12 col-lg-6 col-md-12 my-2">
            {config.isChartJsEnabled ? (
              <CountGraph
                count={
                  state.trainingEmployeeCount.map(
                    (data) => data.training_employee_count
                  ) ?? 0
                }
                radius={"77%"}
                cutout={"75%"}
                height={100}
                 fontWeight={500}
                        fontSize={"1.5rem"}
                color={state.trainingEmployeeCount.map((data) => data.color)}
              />
            ) : (
              <HighchartsReact
                highcharts={Highcharts}
                options={employeeCount}
              />
            )}
            <div class="w-100 text-center">
              <span class="font-weight-bold fs-5">Employee</span>
            </div>
          </div>
          <div className="col-12 col-lg-6 col-md-12 my-2">
            {config.isChartJsEnabled ? (
              <CountGraph
                count={
                  state.trainingContractorCount.map(
                    (data) => data.training_contractor_count
                  ) ?? 0
                }
                radius={"77%"}
                cutout={"75%"}
                height={100}
                 fontWeight={500}
                        fontSize={"1.5rem"}
                color={state.trainingContractorCount.map((data) => data.color)}
              />
            ) : (
              <HighchartsReact
                highcharts={Highcharts}
                options={contractorCount}
              />
            )}
            <div class="w-100 text-center">
              <span class="font-weight-bold fs-5">Contractor</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailGraphSection;
